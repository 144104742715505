import React, { useState } from "react"
import { navigate } from "gatsby"
import { GoogleApiWrapper, Map, Marker } from "google-maps-react"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import NavigationBar from "../../components/NavigationBar"
import EbookModal from "../../components/EBookModal"
import eBookImage from "../../images/ebook.png"
import phoneIcon from "../../images/phoneIcon.png"
import peopleIcon from "../../images/peopleIcon.png"
import pointIcon from "../../images/pointIcon.png"
import testimonialOne from "../../images/home-testimonial-one.png"
import testimonialTwo from "../../images/home-testimonial-two.png"

import "./style.scss"

const isClient = typeof window !== "undefined"

const navigateTo = route => {
  navigate(route)
}

const IndexPage = props => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { google } = props

  return (
    <Layout>
      <SEO title="Home" />
      {show && <EbookModal handleClose={handleClose} />}
      <div className="homePageWrapper">
        <NavigationBar />
        <div className="banner">
          <div className="container">
            <h1>Empowering the most important people in your world</h1>
            <p className="summary">
              Our Martial Arts programme is uniquely designed to develop
              character, confidence and the ability to deal with everyday
              situations as a young person. It promotes a positive mindset and
              leadership skills, incorporating valuable self-defence skills,
              while keeping fit and having fun at the same time!
            </p>
            <p className="caption"></p>
            <div
              className="button cta"
              role="button"
              tabIndex="0"
              onKeyDown={() => navigateTo("get-started")}
              onClick={() => navigateTo("get-started")}
            >
              Get started now
            </div>
          </div>
        </div>
        <div className="eBookWrapper">
          <div className="container">
            <div className="content">
              <div className="eBookImage">
                <img src={eBookImage} alt="eBook" />
              </div>
              <div className="eBookContent">
                <h1>
                  Check out 5 life changing steps to develop your child's
                  character faster than anything you've ever experienced
                </h1>
                <div
                  className="button cta"
                  role="button"
                  tabIndex="0"
                  onKeyDown={handleShow}
                  onClick={handleShow}
                >
                  Get Your Free Copy
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainContentWrapper">
          <div className="container">
            <div className="content">
              <div className="column">
                <h2>Why Martial Arts?</h2>
                <ul>
                  <li>
                    <h3 className="title">1. Focus and self discipline</h3>
                  </li>
                  <li>
                    <h3 className="title">2. Confidence &amp; leadership</h3>
                  </li>
                  <li>
                    <h3 className="title">3. Valuable self-defence skills</h3>
                  </li>
                  <li>
                    <h3 className="title">
                      4. Mental and physical development
                    </h3>
                  </li>
                </ul>
              </div>
              <div className="column">
                <div className="iconCard">
                  <div className="icon">
                    <img src={phoneIcon} alt="phone icon" />
                  </div>
                  <div className="iconImageContent">
                    <h3>Book a Call</h3>
                    <p>
                      Use the form on this page to book a time that works for
                      you for a short consultation call. We’ll call you promptly
                      at that time to get to know you &amp; your child better,
                      and provide you with all of the information you need to
                      see if we are a great fit.
                    </p>
                  </div>
                </div>

                <div className="iconCard">
                  <div className="icon">
                    <img src={peopleIcon} alt="people icon" />
                  </div>
                  <div className="iconImageContent">
                    <h3>Come In For a Meeting</h3>
                    <p>
                      We will meet &amp; greet you at our unique training centre
                      where you will witness instant transformation, great
                      positive energy and tons of fun. Your child will receive
                      full uniform, break a real board and achieve their first
                      belt - their white belt.
                    </p>
                  </div>
                </div>

                <div className="iconCard">
                  <div className="icon">
                    <img src={pointIcon} alt="point icon" />
                  </div>
                  <div className="iconImageContent">
                    <h3>Choose the Programme for You</h3>
                    <p>
                      You might be just looking to improve your child fitness or
                      or boost their confidence. We will work with you to find
                      the right programme for you and your parenting goals. For
                      a monthly fee, you get far more than you’d expect.
                    </p>
                  </div>
                </div>

                <div
                  className="button cta"
                  role="button"
                  tabIndex="0"
                  onKeyDown={() => navigateTo("get-started")}
                  onClick={() => navigateTo("get-started")}
                >
                  Get started now
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonialsWrapper">
          <div className="container">
            <div className="testimonials">
              <div className="testimonial">
                <img src={testimonialOne} alt="Testimonial" />
              </div>
              <div className="testimonial">
                <img src={testimonialTwo} alt="Testimonial" />
              </div>
            </div>
          </div>
        </div>
        <div className="mapContainer">
          {isClient && (
            <Map
              google={google}
              containerStyle={{ position: "relative" }}
              initialCenter={{
                lat: 51.340312,
                lng: 0.557937,
              }}
              zoom={15}
            >
              <Marker
                title={"Sega Fitness"}
                position={{ lat: 51.340312, lng: 0.557937 }}
              />
            </Map>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDQ2-hHvErwL8F56DACl9bagXqAeBPt0ts",
})(IndexPage)
