import React from "react"
import { Helmet } from "react-helmet"

import "./style.scss"

const EbookModal = ({ handleClose }) => {
  return (
    <div className="modal">
      <Helmet>
        <script
          src="https://academy-kravmaga.activehosted.com/f/embed.php?id=3"
          type="text/javascript"
          charset="utf-8"
        ></script>
      </Helmet>
      <div className="modal-content">
        <div className="modelHeader">
          <span
            className="close"
            aria-label="Close"
            role="button"
            tabIndex="0"
            onClick={handleClose}
            onKeyPress={handleClose}
          >
            &times;
          </span>
          <h2>Get Your Free Ebook</h2>
        </div>
        <div class="_form_3"></div>
      </div>
    </div>
  )
}

export default EbookModal
